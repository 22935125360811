import { NavItemBaseProps } from '@components/nav-section/types';
import { Box, Stack } from '@mui/material';
import { hideScroll } from 'src/theme/css';
import NavList from 'src/components/nav-section/mini/nav-list';
import { NAV } from '../config-layout';

export default function SideNav({ sideNavData }: { sideNavData: NavItemBaseProps[] }) {
  return (
    <Box
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_MINI },
        zIndex: 10,
      }}
    >
      <Stack
        sx={{
          pb: 2,
          height: 1,
          position: 'fixed',
          bgcolor: 'background.default',
          borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          width: NAV.W_MINI,
          ...hideScroll.x,
        }}
      >
        <Stack component="nav" id="nav-section-mini" sx={{ pt: 8 }} spacing={2}>
          {sideNavData.map((list) => (
            <NavList key={list.title} data={list} />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
