import { lazy, Suspense } from 'react';

import { AuthGuard, MFAGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const RiskRanker = lazy(() => import('src/pages/risk/rr'));
const UploadComplaint = lazy(() => import('src/pages/risk/upload'));
const SingleRiskAnalysis = lazy(() => import('src/pages/risk/analysis'));
const RiskGridPage = lazy(() => import('src/pages/risk/risk-grid'));
const MappedRowsPage = lazy(() => import('src/pages/risk/map-rows'));
const RiskHome = lazy(() => import('src/pages/risk/risk-home'));
const RiskReportPage = lazy(() => import('src/pages/risk/report'));

// ----------------------------------------------------------------------

const routesConfig = [
  { path: 'risk-home', Component: RiskHome },
  { path: 'rr', Component: RiskRanker },
  { path: 'rr/upload', Component: UploadComplaint },
  { path: 'rr/single', Component: SingleRiskAnalysis },
  { path: 'rr/grid', Component: RiskGridPage },
  { path: 'rr/map', Component: MappedRowsPage },
  { path: 'rr/report', Component: RiskReportPage },
];

const GuardedRoute = ({ Component }: { Component: React.ComponentType }) => (
  <AuthGuard>
    <MFAGuard>
      <DashboardLayout>
        <Suspense fallback={<LoadingScreen />}>
          <Component />
        </Suspense>
      </DashboardLayout>
    </MFAGuard>
  </AuthGuard>
);

export const riskRoutes = routesConfig.map(({ path, Component }) => ({
  path,
  element: <GuardedRoute Component={Component} />,
}));
