import { useMemo, useEffect, useReducer, useCallback } from 'react';

import AuthAPI from 'src/api/auth';
import TenantAPI, { TenantInfo } from 'src/api/tenant';

//

import { useNavigate } from 'react-router-dom';
import { paths } from 'src/routes/paths';
import UsersAPI from 'src/api/users';
import { AuthContext } from './auth-context';
import { setSession, isValidToken, setTenantHeader, setMFAToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  ONBOARD = 'ONBOARD',
  INVITATION_SIGNUP = 'INVITATION_SIGNUP',
  SELECT_TENANT = 'SELECT_TENANT',
  MFA_VERIFIED = 'MFA_VERIFIED',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
    tenant: TenantInfo | null;
    mfa: boolean;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
    tenant: TenantInfo | null;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
  [Types.ONBOARD]: {
    user: AuthUserType;
  };
  [Types.INVITATION_SIGNUP]: undefined;
  [Types.SELECT_TENANT]: {
    tenant: TenantInfo | null;
  };
  [Types.MFA_VERIFIED]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  mfa: false,
  tenant: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
      tenant: action.payload.tenant,
      mfa: action.payload.mfa,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
      tenant: action.payload.tenant,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  if (action.type === Types.ONBOARD) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.SELECT_TENANT) {
    return {
      ...state,
      tenant: action.payload.tenant,
    };
  }
  if (action.type === Types.MFA_VERIFIED) {
    return {
      ...state,
      mfa: true,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const MFA_STORAGE_KEY = 'mfaToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { tenant } = state;

  // Set MFA
  const setMFA = useCallback(async (mfaToken: string) => {
    setMFAToken(mfaToken);
    dispatch({
      type: Types.MFA_VERIFIED,
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const user = await AuthAPI.userProfile();
        let newTenant = tenant;

        if (!tenant) {
          if (user?.profile?.last_logged_in_tenant_id) {
            const tenantID = user.profile.last_logged_in_tenant_id;
            // Get the tenant info
            newTenant = await TenantAPI.getTenantInfo(tenantID);
            setTenantHeader(tenantID);
          } else {
            // Navigate to the select tenant page
            navigate(paths.auth.jwt.selectTenant);
          }
        }

        const mfaToken = localStorage.getItem(MFA_STORAGE_KEY);
        if (mfaToken) {
          setMFAToken(mfaToken);
        }

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              ...user,
              accessToken,
            },
            tenant: newTenant,
            mfa: !!mfaToken,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
            tenant: null,
            mfa: false,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
          tenant: null,
          mfa: false,
        },
      });
    }
  }, [navigate, tenant]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(
    async (email: string, password: string) => {
      const data = {
        email,
        password,
      };

      const { token: accessToken, user, tenant: newTenant } = await AuthAPI.login(data);

      setSession(accessToken);

      if (newTenant) {
        setTenantHeader(newTenant.id);
      } else {
        // Navigate to the select tenant page
        navigate(paths.auth.jwt.selectTenant);
      }

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...user,
            accessToken,
          },
          tenant: newTenant,
        },
      });
    },
    [navigate]
  );

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const { token: accessToken, user } = await AuthAPI.register(data);

      localStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user: {
            ...user,
            accessToken,
          },
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ONBOARD
  const onboard = useCallback(async (token: string, password: string) => {
    const data = {
      token,
      password,
    };

    const { token: accessToken, user } = await AuthAPI.onboardUser(data);

    setSession(accessToken);

    dispatch({
      type: Types.ONBOARD,
      payload: {
        user: {
          ...user,
          accessToken,
        },
      },
    });
  }, []);

  // Invitation Signup
  const invitationSignup = useCallback(
    async (fullname: string, email: string, password: string, token: string) => {
      const data = {
        display_name: fullname,
        email,
        password,
        token,
      };

      await AuthAPI.invitationSignup(data);

      dispatch({
        type: Types.INVITATION_SIGNUP,
      });
    },
    []
  );

  // Select Tenant
  const selectTenant = useCallback(async (newTenant: TenantInfo) => {
    await UsersAPI.selectTenant(newTenant.id);
    setTenantHeader(newTenant.id);
    dispatch({
      type: Types.SELECT_TENANT,
      payload: {
        tenant: newTenant,
      },
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      tenant: state.tenant,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      mfa: !!state.mfa,
      login,
      setMFA,
      register,
      logout,
      onboard,
      invitationSignup,
      selectTenant,
    }),
    [
      login,
      setMFA,
      logout,
      register,
      onboard,
      invitationSignup,
      selectTenant,
      state.mfa,
      state.user,
      state.tenant,
      status,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
