import { useRef } from 'react';

import { useActiveLink } from 'src/routes/hooks/use-active-link';

import NavItem from './nav-item';
import { NavListProps } from '../types';

// ----------------------------------------------------------------------

export default function NavList({ data }: NavListProps) {
  const navRef = useRef<HTMLDivElement | null>(null);
  const isLinkActive = useActiveLink(data.path, !!data.children);

  return (
    <NavItem
      ref={navRef}
      //
      title={data.title}
      path={data.path}
      icon={data.icon}
      disabled={data.disabled}
      //
      externalLink={data.path.includes('http')}
      //
      active={isLinkActive}
      className={isLinkActive ? 'active' : ''}
    />
  );
}
