import { lazy, Suspense } from 'react';

import { AuthGuard, MFAGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const Complaints = lazy(() => import('src/pages/dashboard/complaints'));
const Mapping = lazy(() => import('src/pages/dashboard/mapping'));
const Harms = lazy(() => import('src/pages/dashboard/harms'));
const PMS = lazy(() => import('src/pages/dashboard/pms'));
const PSU = lazy(() => import('src/pages/dashboard/psu'));
const LIT = lazy(() => import('src/pages/dashboard/lit'));
const CER = lazy(() => import('src/pages/dashboard/cer'));
const Reportabilty = lazy(() => import('src/pages/dashboard/reportability'));
const Investigation = lazy(() => import('src/pages/dashboard/investigation'));
const PatientInformation = lazy(() => import('src/pages/dashboard/product-information'));
const PatientComplaints = lazy(() => import('src/pages/dashboard/patient-complaints'));
const Risk = lazy(() => import('src/pages/dashboard/risk'));
const ClinicalLiterature = lazy(() => import('src/pages/dashboard/clinical-literature'));
const UserProfile = lazy(() => import('src/pages/dashboard/profile'));
const NewDashboard = lazy(() => import('src/pages/dashboard/adv'));
const AdverseEvents = lazy(() => import('src/components/adverse'));
const Recalls = lazy(() => import('src/components/recalls'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <NewDashboard />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'complaints', element: <Complaints /> },
      { path: 'mapping', element: <Mapping /> },
      { path: 'harms', element: <Harms /> },
      { path: 'pms', element: <PMS /> },
      { path: 'psu', element: <PSU /> },
      { path: 'lit', element: <LIT /> },
      { path: 'cer', element: <CER /> },
      { path: 'reportability', element: <Reportabilty /> },
      { path: 'product-information', element: <PatientInformation /> },
      { path: 'patient-complaints', element: <PatientComplaints /> },
      { path: 'risk', element: <Risk /> },
      { path: 'clinical-literature', element: <ClinicalLiterature /> },
      { path: 'profile', element: <UserProfile /> },
      { path: 'investigation', element: <Investigation /> },
      { path: 'adverse-events', element: <AdverseEvents /> },
      { path: 'recalls', element: <Recalls /> },
    ],
  },
];
