import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { AuthGuard, MFAGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

const UserProfile = lazy(() => import('src/pages/user/profile'));
const ManagePeople = lazy(() => import('src/pages/tenant/users'));

export const userRoutes = [
  {
    path: 'user',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: '', // Empty path for /user route
        element: <Navigate to="profile" />,
      },
      {
        path: 'profile',
        element: <UserProfile />,
      },
      {
        path: 'manage-people',
        element: <ManagePeople />,
      },
    ],
  },
];
