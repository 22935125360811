import { AppBar, Toolbar, Typography, Box, Stack, Link } from '@mui/material';
import { RouterLink } from 'src/routes/components';

import InviteUsersForm from 'src/components/invite-users-form';
import Logo from 'src/components/common/logo';

import { useAuthContext } from 'src/auth/hooks';
import { useTheme } from '@mui/material/styles';

import LuminaIcon from 'src/assets/icons/header/lumina';
import AccountPopover from '../common/account-popover';
import { NavItem } from './config-navigation';

interface HeaderProps {
  activeSectionID: string;
  navItems: NavItem[];
}

export default function Header({ activeSectionID, navItems }: HeaderProps) {
  const { tenant } = useAuthContext();
  const theme = useTheme();

  return (
    <AppBar
      position="fixed"
      sx={{
        background: theme.palette.background.paper,
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
        minHeight: '48px',
      }}
    >
      <Toolbar variant="dense" sx={{ minHeight: '48px', height: '48px' }}>
        <Stack direction="row">
          <Logo single />
          <Typography
            variant="h6"
            color={theme.palette.text.primary}
            sx={{ fontWeight: 'bold', alignSelf: 'center', marginLeft: 1 }}
          >
            {tenant?.name}
          </Typography>
        </Stack>
        <Box sx={{ flexGrow: 1, justifyContent: 'space-around' }} />
        <Stack
          component="nav"
          direction="row"
          spacing={2}
          sx={{
            ml: 8,
          }}
        >
          {navItems.map((item) => (
            <Link
              component={RouterLink}
              key={item.id}
              href={item.children[0]?.path}
              underline="none"
              aria-disabled={item.isDisabled}
              sx={{
                display: 'flex',
                pointerEvents: item.isDisabled ? 'none' : 'auto',
                alignItems: 'center',
                color: item.isDisabled ? theme.palette.text.disabled : theme.palette.text.primary,
                borderBottom:
                  item.id === activeSectionID ? '2px solid black' : '2px solid transparent',

                py: 1,
                px: 2,
                height: '48px',
              }}
            >
              {item.id === 'askLumina' && <LuminaIcon height={28} width={28} />}
              <Typography variant="body1" sx={{ ml: 1, fontSize: 14 }}>
                {item.label}
              </Typography>
            </Link>
          ))}
        </Stack>
        <Box sx={{ flexGrow: 1, justifyContent: 'space-around' }} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <InviteUsersForm hasSoftButton />
          <AccountPopover />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
