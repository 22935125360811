import { lazy, Suspense } from 'react';

import { AuthGuard, MFAGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

const ProductHub = lazy(() => import('src/pages/product-hub'));
const ProductDetails = lazy(() => import('src/pages/product-hub/product-details'));
const EditProduct = lazy(() => import('src/pages/product-hub/edit-product'));
const CustomerComplaints = lazy(() => import('src/pages/product-hub/customer-complaints'));
const ComplaintDataset = lazy(
  () => import('src/pages/product-hub/customer-complaints/complaint-dataset')
);

export const productHubRoutes = [
  {
    path: 'product-hub/:familyId/product/:productId/complaints',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <CustomerComplaints />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: ':complaintDatasetId',
        element: <ComplaintDataset />,
      },
    ],
  },
  {
    path: 'product-hub',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <ProductHub />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
  },
  {
    path: 'product-hub/:familyId',
    element: (
      <AuthGuard>
        <MFAGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <ProductHub />
            </Suspense>
          </DashboardLayout>
        </MFAGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'product/:productId',
        element: <ProductDetails />,
      },
      {
        path: 'product/:productId/edit',
        element: <EditProduct />,
      },
    ],
  },
];
