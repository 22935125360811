import { useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import { PATH_AFTER_LOGIN } from 'src/config-global';

import { Alert, IconButton, MenuItem, Select, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'src/utils/axios';
import { useQuery } from '@tanstack/react-query';

import LoadingButton from '@mui/lab/LoadingButton';
import { useNavigate } from 'react-router-dom';
import { TenantInfo } from 'src/api/tenant';

const fetchTenants = async () => {
  const { data } = await axios.get('/tenants');
  return data;
};

export default function JwtSelectTenantView(props: { onClose?: () => void }) {
  const { selectTenant, user } = useAuthContext();
  const { onClose } = props;

  const navigate = useNavigate();

  const { data: tenants, isLoading: tenantsLoading } = useQuery({
    queryKey: ['tenants'],
    queryFn: fetchTenants,
  });

  const [errorMsg, setErrorMsg] = useState('');
  const [selectedTenant, setSelectedTenant] = useState({
    id: '',
    name: 'Select Tenant',
  });

  if (tenants?.length) {
    const defaultTenant =
      tenants.find((tenant: any) => tenant.id === user?.profile?.last_logged_in_tenant_id) ||
      tenants[0];
    if (defaultTenant && !selectedTenant.id) {
      setSelectedTenant(defaultTenant);
    }
  }

  const onSubmit = async () => {
    try {
      await selectTenant(selectedTenant as TenantInfo);
      onClose?.();
      navigate(PATH_AFTER_LOGIN);
    } catch (error) {
      setErrorMsg(typeof error === 'string' ? error : `Something went wrong.`);
    }
  };

  const handleTenantChange = (e: any) => {
    const tenant = tenants?.find((t: any) => t.id === e.target.value);
    setSelectedTenant(tenant || { id: '', name: 'Select Tenant' });
  };

  return (
    <>
      {!!errorMsg && (
        <Alert
          severity="error"
          sx={{ mb: 2 }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorMsg('');
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorMsg}
        </Alert>
      )}
      <Stack spacing={1.5} sx={{ mb: 2 }}>
        <Select
          value={selectedTenant.id}
          onChange={handleTenantChange}
          displayEmpty
          disabled={tenantsLoading}
        >
          {tenants?.map((tenant: any) => (
            <MenuItem key={tenant.id} value={tenant.id}>
              {tenant.name}
            </MenuItem>
          ))}
        </Select>
        <LoadingButton
          onClick={onSubmit}
          type="button"
          variant="contained"
          color="primary"
          loading={tenantsLoading}
          sx={{ mt: 2 }}
        >
          Select
        </LoadingButton>
      </Stack>
    </>
  );
}
