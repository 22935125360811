type HeaderSvgProps = {
  width: string | number;
  height: string | number;
};

const Reportcon: React.FC<HeaderSvgProps> = ({ width, height }: HeaderSvgProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    xmlSpace="preserve"
    version="1.1"
  >
    <image
      width={width}
      height={height}
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABCCAYAAADnodDVAAAAAXNSR0IArs4c6QAAAIRlWElmTU0AKgAAAAgABQESAAMAAAABAAEAAAEaAAUAAAABAAAASgEbAAUAAAABAAAAUgEoAAMAAAABAAIAAIdpAAQAAAABAAAAWgAAAAAAAACQAAAAAQAAAJAAAAABAAOgAQADAAAAAQABAACgAgAEAAAAAQAAAECgAwAEAAAAAQAAAEIAAAAALqnwRQAAAAlwSFlzAAAWJQAAFiUBSVIk8AAABDBJREFUeAHtW01IVFEUvveOWqb2g0UW2C7IhQXVzojRGYIWWjPjTz8YUVEzs4ko2rhoUW2iTRtHRfqxiFBHAyNTZnTAopVFiSXUwlqUYEJRmqa+27mSm3lXfT9n9E3zLjyGd865533ne+fce999bwixm82AzYDNgM1A6jJA9YTu8gZPE6bs1tNHry3lpDESrnutt59Re10EuMsDzYTQCqMX09KPc/4TQHkj4VBEi71ZG2bWAXZ/SmkOp+Sp2xs4hu1b5s9yBAiQlNB0IOGBy+u/KAONKUsz44wT/gj6D5jxAcFel/WHTAAVveny+fOj4boLYMNldmZlJgkgbT2toRYzINzlQSkB8z4pZefdvuCWr0Oj1YODLX/m5Vi/1isBTmCgjWuUVObt2Njldp9dF6cxfWo5AmbG3h2HmSAcHxlUhJOsT+srKju1NV5n5txyBMRisZnvw7NHgYTHksAKMzNWvdzvCRZIdIZEliNARNHf3zA9MvStCkh4oo6Kbstw8BfFXn+RWqdfYkkCRBhiwAMSfEBCpzosuoExGnH5AofVOn0SyxIgwhAkfJoY9hDOu+PDgjlyNYwLYbfPH4jX6Tm3NAEikI+dnVMzY78PwSogKgmMEcpqgYRrEp0mkeUJEFHEYncnx7+MlkI59EqjoqzG5QvecTqdutc1ujtIASAKS7z+6oXcwVKwGR6U9oB+bbwNrBtPOnILNu88kF/xtvv+eLx+oXPLEcAYa1oI7FJyGBMObsrJvgV2Z5ayndcnRQnMg9XyC5mgyo7F+v13BCwWrEy34iUAA1uHDJhWGdxxWBpTMS4YaitOQDQcKjOE/F+nkvJABaSx+gFKo1NUAlzlgXswX3sWuzYErKtGF/OFoUMlAKaoTNjEyMEAtlw+UAngnLyHjZu+5QKPcR1UAiC9rwAocSRNs6dBzFs1t5VNaSGmT5UvzgcibaGHKrlBAWoJEEbE83lCX5zAnrnYhEUjwC4Bg5kj7zY1ewmy4KpciSRVyA8kT3NuUEsg0tHwGRPccvhCJcDlOZE7rWSvSSTwdPZrItreNIZ1DVQCqCMrlOFI8CBIssQgWIlFgD0IYjEp/CgK6WaUo6WnDJvCSb9MblSGWgI9baFGACKOpGl2CWDeKviG6DKhfB+mT5UvTp9H22pvqOQGBaglQBnfC9tTpQaxaOtG+aQ2Q21Wdglo40mbVaQ1VAWWR7RZG7aC9yN4DbUEAJYAhwoQL1S5J1QCij3ndhFG8+SXQpIqfKS3vf4NkjeCSoDDwWoS/SElcXB7KYx194Uf1Azgs8pt+KwtobvCnCsfLEtAtL3+GYATR9K0lF8H2AQkTa4mCGjKZ4CpWUD8sQHeCG9P0M3R6rZQq6HMziQBVKz7xZG0LeVLIOUJ0FUCXKFd8K+xUUvnu8JeWRqfDc5mwGbAZsBKDPwFL5/1dT7XtPIAAAAASUVORK5CYII="
    />
  </svg>
);
export default Reportcon;
