import axios, { endpoints } from 'src/utils/axios';

export default class UsersAPI {
  static async selectTenant(tenantID: string) {
    const { data } = await axios.put(endpoints.users.selectTenant, {
      last_logged_in_tenant_id: tenantID,
    });
    return data;
  }
}
