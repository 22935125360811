import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

// import { useBoolean } from 'src/hooks/use-boolean';
import GlobalHeader from './global-header';
import SideNav from './side-nav';
// import { useHeader } from './useHeader';
import { useNavData } from './config-navigation';

import Main from './main';
// import Header from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function DashboardLayout({ children }: Props) {
  const { navItems } = useNavData();
  const location = useLocation();
  const activeSectionData =
    navItems.find((obj) => obj.children.some((item) => location.pathname.includes(item.path))) ||
    navItems[0];
  const activeSectionID = activeSectionData.id;
  const sideNavData = activeSectionData.children;

  return (
    <>
      {/* <Header onOpenNav={nav.onTrue} /> */}
      <GlobalHeader activeSectionID={activeSectionID} navItems={navItems} />
      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          bgcolor: 'background.page',
        }}
      >
        <SideNav sideNavData={sideNavData} />

        <Main>{children}</Main>
      </Box>
    </>
  );
}
