import { lazy, Suspense } from 'react';

import { AuthGuard } from 'src/auth/guard';
import DashboardLayout from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

const IntelliRiskHome = lazy(() => import('src/pages/intellirisk/home'));
const ComplaintRiskAnalysisHome = lazy(() => import('src/pages/intellirisk/complaints/home'));
const ReviewComplaintsPage = lazy(() => import('src/pages/intellirisk/complaints/review'));
const RiskMappingPage = lazy(() => import('src/pages/intellirisk/complaints/mapping'));
const ResultGridPage = lazy(() => import('src/pages/intellirisk/complaints/result-grid-page'));
const SummaryPage = lazy(() => import('src/pages/intellirisk/complaints/summary-page'));
// ----------------------------------------------------------------------

const routesConfig = [
  { path: 'intellirisk', Component: IntelliRiskHome },
  { path: 'intellirisk/complaints', Component: ComplaintRiskAnalysisHome },
  { path: 'intellirisk/complaints/family/:familyId', Component: ComplaintRiskAnalysisHome },
  {
    path: 'intellirisk/complaints/family/:familyId/product/:productId',
    Component: ComplaintRiskAnalysisHome,
  },
  { path: 'intellirisk/complaints/:productId/review/:datasetId', Component: ReviewComplaintsPage },
  {
    path: 'intellirisk/complaints/:productId/dataset/:datasetId/map/:analysisId',
    Component: RiskMappingPage,
  },
  {
    path: 'intellirisk/complaints/:productId/dataset/:datasetId/grid/:analysisId',
    Component: ResultGridPage,
  },
  {
    path: 'intellirisk/complaints/:productId/dataset/:datasetId/summary/:analysisId',
    Component: SummaryPage,
  },
];

const GuardedRoute = ({ Component }: { Component: React.ComponentType }) => (
  <AuthGuard>
    <DashboardLayout>
      <Suspense fallback={<LoadingScreen />}>
        <Component />
      </Suspense>
    </DashboardLayout>
  </AuthGuard>
);

export const intelliriskRoutes = routesConfig.map(({ path, Component }) => ({
  path,
  element: <GuardedRoute Component={Component} />,
}));
