import { useState, useEffect } from 'react';

import { alpha } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import Logo from '../common/logo';

// ----------------------------------------------------------------------

export default function SplashScreen({ sx, ...other }: BoxProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9998,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        ...sx,
      }}
      {...other}
    >
      <>
        <Box>
          <Logo single disabledLink sx={{ width: 100, height: 100 }} />
          <LinearProgress />
        </Box>

        <Box
          sx={{
            border: (theme) => `solid 1px ${alpha(theme.palette.primary.dark, 0.24)}`,
          }}
        />
      </>
    </Box>
  );
}
