// ----------------------------------------------------------------------

export const HEADER = {
  H_MOBILE: 48,
  H_DESKTOP: 48,
  H_DESKTOP_OFFSET: 48 - 16,
};

export const NAV = {
  W_VERTICAL: 260,
  W_MINI: 120,
};
