import { lazy } from 'react';

import { AuthGuard } from 'src/auth/guard';
import AuthClassicLayout from 'src/layouts/auth/classic';

// ----------------------------------------------------------------------

const GenerateOTP = lazy(() => import('src/sections/auth/mfa/generate-otp'));
const VerifyOTP = lazy(() => import('src/sections/auth/mfa/verify-otp'));
// ----------------------------------------------------------------------

const mfaChildren = [
  {
    path: 'generate-otp',
    element: (
      <AuthGuard>
        <AuthClassicLayout>
          <GenerateOTP />
        </AuthClassicLayout>
      </AuthGuard>
    ),
  },
  {
    path: 'verify-otp',
    element: (
      <AuthGuard>
        <AuthClassicLayout>
          <VerifyOTP />
        </AuthClassicLayout>
      </AuthGuard>
    ),
  },
];

export const mfaRoutes = [
  {
    path: 'mfa',
    children: mfaChildren,
  },
];
