import axios, { endpoints } from 'src/utils/axios';

export interface TenantInfo {
  id: string;
  name: string;
  status: string;
  metadata: {
    competent_authority: string;
    notified_body: string;
    industry: string;
    onboarding_status: string;
    default_procodes: {
      id: string;
    }[];
    manufacturers: {
      id: string;
    }[];
    default_competitors: {
      id: string;
    }[];
  };
}

export interface Plan {
  id: string;
  name: string;
  description: string;
  smart_viz: boolean;
  intellirisk: boolean;
  nca: boolean;
  competitive_intelligence: boolean;
  insights: boolean;
  datasources: boolean;
  product_hub: boolean;
}

export default class TenantAPI {
  static async getTenantInfo(tenantID: string): Promise<TenantInfo> {
    const { data } = await axios.get(endpoints.tenants.info, {
      params: {
        tenant_id: tenantID,
      },
    });

    return data;
  }

  static async getTenantPlan(): Promise<Plan> {
    const { data } = await axios.get(endpoints.tenants.plan);
    return data;
  }
}
