import { Navigate, useRoutes } from 'react-router-dom';

import { PATH_AFTER_LOGIN } from 'src/config-global';

import { mfaRoutes } from './mfa';
import { userRoutes } from './user';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
import { riskRoutes } from './risk-demo';
import { dashboardRoutes } from './dashboard';
import { productHubRoutes } from './product-hub';
import { intelliriskRoutes } from './intellirisk';
import { tenantRoutes } from './tenant';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
    },

    // Auth routes
    ...authRoutes,

    // MFA routes
    ...mfaRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // User routes
    ...userRoutes,

    // Product hub routes
    ...productHubRoutes,

    // Risk routes
    ...riskRoutes,

    // IntelliRisk routes
    ...intelliriskRoutes,
    // Tenant routes
    ...tenantRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
