import { SnackbarProvider } from 'notistack';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import Router from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import ThemeProvider from 'src/theme';

import ProgressBar from 'src/components/common/progress-bar';
import { SettingsProvider } from 'src/components/common/settings';
//

import { AuthProvider } from 'src/auth/context/jwt';
import { MaudeProvider } from './context/maude-provider';

// ----------------------------------------------------------------------

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light',
          themeDirection: 'ltr',
          themeContrast: 'default',
          themeLayout: 'vertical',
          themeColorPresets: 'blue',
          themeStretch: true,
        }}
      >
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <SnackbarProvider maxSnack={5} autoHideDuration={5000} />
            <MaudeProvider>
              <ProgressBar />
              <Router />
            </MaudeProvider>
          </QueryClientProvider>
        </ThemeProvider>
      </SettingsProvider>
    </AuthProvider>
  );
}
