import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';

import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import JwtSelectTenantView from 'src/sections/auth/jwt/select-tenant-view';
import { Transition } from 'src/components/transition';
// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: 'Home',
    linkTo: paths.dashboard.root,
    onClick: () => {},
  },
  {
    label: 'Profile',
    linkTo: paths.user.profile,
    onClick: () => {},
  },
  {
    label: 'Manage People',
    linkTo: paths.user.people,
    onClick: () => {},
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const { logout, user } = useAuthContext();
  const allTenants = user?.all_tenants;
  const [openSwitchTenant, setOpenSwitchTenant] = useState(false);
  const [menuOptions, setMenuOptions] = useState(OPTIONS);

  useEffect(() => {
    if (allTenants?.length > 1) {
      setMenuOptions([
        ...OPTIONS,
        {
          label: 'Switch Tenant',
          linkTo: '',
          onClick: () => {
            setOpenSwitchTenant(true);
          },
        },
      ]);
    } else {
      setMenuOptions(OPTIONS);
    }
  }, [allTenants]);

  const popover = usePopover();

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickItem = (item: (typeof OPTIONS)[number]) => {
    popover.onClose();
    if (item.linkTo) {
      router.push(item.linkTo);
    } else if (item.onClick) {
      item.onClick();
    }
  };

  return (
    <>
      <IconButton
        onClick={popover.onOpen}
        sx={{
          width: 32,
          height: 32,
          background: (theme) => alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: (theme) =>
              `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        <Avatar
          src={user?.photoURL}
          alt={user?.display_name}
          sx={{
            width: 32,
            height: 32,
          }}
        >
          {user?.display_name?.charAt(0).toUpperCase()}
        </Avatar>
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.display_name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {menuOptions.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Logout
        </MenuItem>
      </CustomPopover>
      <Dialog
        open={openSwitchTenant}
        fullWidth
        maxWidth="sm"
        onClose={() => setOpenSwitchTenant(false)}
        TransitionComponent={Transition}
      >
        <DialogTitle>Switch Tenant</DialogTitle>
        <DialogContent>
          <JwtSelectTenantView onClose={() => setOpenSwitchTenant(false)} />
        </DialogContent>
      </Dialog>
    </>
  );
}
